import React, { useState } from 'react';
import { createClient } from '../../plugins/contentful';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useEffect } from 'react';
import "./styles.scss";
import { camelCase } from 'lodash';
import { useTranslation } from "react-i18next";
import { useParams, withRouter } from "react-router";
import { HashLink } from 'react-router-hash-link';
import FadeInOutWrapper from "../../components/FadeInOutWrapper";
import Fade from 'react-reveal/Fade';
import { useGA4React } from 'ga-4-react';


import { AnimateSharedLayout, AnimatePresence, motion } from 'framer-motion';
const MotionDiv = motion.div;
const MotionH4 = motion.h4;
const MotionButton = motion.button;

const ProductsPage = (props) => { 
  const client = createClient();
  const [ products, setProducts ] = useState();
  const [ categories, setCategories] = useState();
  const [ loaded, setLoaded ] = useState(false);
  const { t, i18n } = useTranslation();
  const { lang } = useParams();
  const ga = useGA4React(process.env.REACT_APP_GA);

  useEffect(() => {
    if (ga) {
      if (lang) {
        ga.pageview(`/${lang}/products`);
      } else {
        ga.pageview("/products");
      }
    }
  }, [ga]);

  useEffect(() => {
    if ( lang !== i18n.language ) {
      i18n.changeLanguage(lang);
    }
  }, []);

  useEffect(() => {
    (async() => {
      const products = await client
      .getEntries({
        'content_type': 'product',
        'order': 'sys.createdAt',
        'locale': lang === 'en' ? 'en-US': 'zh-Hant-HK',
      })
      .then(({ items, includes: { Asset, Entry } }) => {

        let pulses = Entry.find(entry => entry.sys.id === process.env.REACT_APP_PRODUCT_PULSES_ID);
        let starches = Entry.find(entry => entry.sys.id === process.env.REACT_APP_PRODUCT_STARCHES_ID);
        let others = Entry.find(entry => entry.sys.id === process.env.REACT_APP_PRODUCT_OTHERS_ID);
        setCategories({
          pulses: {
            name: pulses.fields.name,
            coverImage: Asset.find(asset => asset.sys.id === pulses.fields.coverImage.sys.id).fields.file.url,
          },
          starches: {
            name: starches.fields.name,
            coverImage: Asset.find(asset => asset.sys.id === starches.fields.coverImage.sys.id).fields.file.url,
          },
          others: {
            name: others.fields.name,
            coverImage: Asset.find(asset => asset.sys.id === others.fields.coverImage.sys.id).fields.file.url,
          },
        });

        return items.map(item => {
          let product = {
            title: item.fields.title,
          }
          if (item.fields.images && item.fields.images.length) {
            let images = item.fields.images.map(image => {
              let imageRes = Asset.find(asset => asset.sys.id === image.sys.id);
              return imageRes.fields.file.url;
            })
            product.images = images;
            
          }
          let category = Entry.find(entry => entry.sys.id === item.fields.category.sys.id);
          product.category = {
            name: category.fields.name,
            id: category.sys.id,
          };
          if (item.fields && item.fields.description) {
            product.description = item.fields.description;
          }
          return product;
      });
    });
    setProducts(products);
    setLoaded(true);
    })();
  }, [lang]);
  const [selectedPulse, setSelectedPulse] = useState(null);
  return pug`
  FadeInOutWrapper
    .bg-white.w-full
      .container.mx-auto
        Carousel(showThumbs=false)
          div.bg-cover(style={ height: "400px", backgroundImage: "url(https://images.unsplash.com/photo-1564894809611-1742fc40ed80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1051&q=80)"})
          div.bg-cover(style={ height: "400px", backgroundImage: "url(https://images.unsplash.com/photo-1472141521881-95d0e87e2e39?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1052&q=80)"})
          div.bg-cover(style={ height: "400px", backgroundImage: "url(https://images.unsplash.com/photo-1579705745811-a32bef7856a3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80)"})
    .bg-white.w-full.my-20
      .container.mx-auto.flex.flex-row.flex-wrap.justify-around
        HashLink.w-auto.border-b-2.border-gray-600(to="/"+i18n.language+"/products#pulses" smooth=true)
          img.inline-block.w-20(src="/images/svg/beans.svg")
          h5.text-lg.font-bold.my-4 #{t('products.pulses')}
        HashLink.w-auto.border-b-2.border-gray-600(to="/"+i18n.language+"/products#starches" smooth=true)
          img.inline-block.w-16(src="/images/svg/starches.svg")
          h5.text-lg.font-bold.my-4 #{t('products.starches')}
        HashLink.w-auto.border-b-2.border-gray-600(to="/"+i18n.language+"/products#others" smooth=true)
          img.inline-block.w-16(src="/images/svg/others.svg")
          h5.text-lg.font-bold.my-4 #{t('products.others')}
    AnimateSharedLayout(type="crossfade")
      AnimatePresence(initial={ opacity: 0, scale: 0.75 } animate={ opacity: 1, scale: 1 } exit={ opacity: 0, scale: 0 })
        if (selectedPulse)
          MotionDiv.fixed.px-2.w-screen.h-screen.top-0.left-0.flex.flex-col.justify-center.items-center.z-50.cursor-pointer(className="sm:px-3 md:px-5" layoutId="selectedLayout1" initial={ backgroundColor: "rgba(0, 0, 0, 0)" } animate={ backgroundColor: "rgba(0, 0, 0, 0.25)"} exit={ backgroundColor: "rgba(0, 0, 0, 0)"} onClick=()=>setSelectedPulse(null)) 
            MotionDiv.shadow-xl.p-6.w-full.rounded-md.bg-white.modal.flex.flex-col.justify-center(inital={opacity: 0} animate={ opacity: 1 } exit={ opacity: 0 } layout onClick=(evt)=>evt.stopPropagation())
              MotionDiv.w-full.bg-contain.bg-no-repeat.bg-center.modal-image(style={backgroundImage: "url("+  selectedPulse.images[0] +")"})
              MotionH4.mt-4 #{selectedPulse.title}
              MotionButton.btn.btn-primary.w-24.mx-auto.rounded.mt-4(onClick=()=>setSelectedPulse(null)) Close
      // Pulses
      if loaded
        .bg-white.w-full.my-20#pulses
          .container.mx-auto
            .bg-overlay.flex.flex-col.justify-center.items-center(style={height: "300px", "--overlay-image": "url("+ categories.pulses.coverImage+")", "--overlay-colors": "#33333391, #3e3e3e6b"})
              .logo.w-20.h-20.bg-white.svg-icon(style={ "--svg-url": "url(/images/svg/beans.svg)", WebkitMaskSize: "contain", maskSize: 'contain' })
              h5.select-none.text-white.text-lg.font-bold.mt-2.border-b-2.border-white #{categories.pulses.name}
            div
              .grid.grid-cols-1.gap-2.grid-flow-row.my-8.mx-6.cursor-pointer(className="sm:mx-0 sm:grid-cols-2 sm:gap-6 md:grid-cols-3 md:gap-8 lg:grid-cols-4")
                each product, idx in products.filter(product => product.category.id === process.env.REACT_APP_PRODUCT_PULSES_ID)
                  Fade(key=idx)
                    MotionDiv.product( layoutId=camelCase(product.title) onClick=()=>setSelectedPulse(product))
                      MotionDiv.w-full.h-56.bg-cover.bg-center(style={backgroundImage: "url("+  product.images[0] +")"})
                      MotionH4.select-none #{product.title}
        // Starches
      if loaded
        .bg-white.w-full.my-20#starches
          .container.mx-auto
            .bg-overlay.flex.flex-col.justify-center.items-center(style={height: "300px", "--overlay-image": "url("+ categories.starches.coverImage+")", "--overlay-colors": "#33333391, #3e3e3e6b"})
              .logo.w-20.h-20.bg-white.svg-icon(style={ "--svg-url": "url(/images/svg/starches.svg)", WebkitMaskSize: "contain", maskSize: 'contain' })
              h5.text-white.text-lg.font-bold.mt-2.border-b-2.border-white.select-none #{categories.starches.name}
            .grid.grid-cols-1.gap-2.grid-flow-row.my-8.mx-6(className="sm:mx-0 md:px-4 lg:grid-cols-2 md:gap-6")
              each product, idx in products.filter(product => product.category.id === process.env.REACT_APP_PRODUCT_STARCHES_ID)
                Fade(key=idx)
                  .product-big
                    .flex.flex-col.justify-start.mr-4.content
                      h4.text-left.my-4.select-none #{product.title}
                      if (product.description)
                        .desc.text-left.select-none(dangerouslySetInnerHTML={__html:product.description })
                    if (product.images && product.images.length > 0)
                      .product-image.bg-cover.bg-center.flex-shrink-0.h-56(className="md:w-1/3" style={ backgroundImage: "url("+ product.images[0]+")", "--overlay-colors": "#33333391, #3e3e3e6b"})
      // Others
      if loaded
        .bg-white.w-full.my-20#others
          .container.mx-auto
            .bg-overlay.flex.flex-col.justify-center.items-center(style={height: "300px", "--overlay-image": "url("+ categories.others.coverImage +")", "--overlay-colors": "#33333391, #3e3e3e6b"})
              .logo.w-20.h-20.bg-white.svg-icon(style={ "--svg-url": "url(/images/svg/others.svg)", WebkitMaskSize: "contain", maskSize: 'contain' })
              h5.text-white.text-lg.font-bold.mt-2.border-b-2.border-white #{categories.others.name}
            .grid.grid-cols-1.gap-2.grid-flow-row.my-8.mx-6(className="sm:mx-0 sm:grid-cols-2 sm:gap-6 md:grid-cols-3 md:gap-8 lg:grid-cols-4")
              each product, idx in products.filter(product => product.category.id === process.env.REACT_APP_PRODUCT_OTHERS_ID)
                Fade(key="others-"+idx)
                  MotionDiv.product.cursor-pointer(layoutId=camelCase(product.title+idx) onClick=()=>setSelectedPulse(product))
                    MotionDiv.w-full.h-56.bg-cover.bg-center(style={backgroundImage: "url("+ product.images[0] +")"})
                    MotionH4 #{product.title}

  `
}

export default withRouter(ProductsPage)