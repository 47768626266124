import React from 'react';
import { motion } from 'framer-motion';

const FadeInWrapper = (props) => {
  return <motion.div initial="hidden" animate="visible" variants={{
    hidden: {
      scale: .4,
      opacity: 0
    },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: .7,
        delay: .4
      },
    }
  }}>
    { props.children }
  </motion.div>
}
export default FadeInWrapper;