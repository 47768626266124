import React, { useCallback, useState } from 'react'
import { matchPath } from "react-router";
import { Link, useLocation, useParams } from 'react-router-dom'
import "./styles.scss";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { NavHashLink } from 'react-router-hash-link';

const Navbar = () => {

  const { t, i18n } = useTranslation();
  const location = useLocation();

  const currentPath = location.pathname;
  const match = matchPath(currentPath, {
    path: "/:lang(en|zh)",
    exact: false,
    strict: false,
  });
  const lang = match?.params?.lang;
  let changeLanguagePath = currentPath;
  if (lang) {
    changeLanguagePath = currentPath.replace(lang, (i18n.language === 'en' ?'zh':'en'));
  } else {
    changeLanguagePath = "/"+ (i18n.language === 'en' ? 'zh' : 'en' ) + changeLanguagePath;
  }
  const [ mobileMenuOpen, setMobileMenuOpen] = useState(false); 
  const toggleMenu = useCallback(() => {
    return setMobileMenuOpen(!mobileMenuOpen);
  }, [mobileMenuOpen]);

  return pug`
nav.shadow.bg-white.sticky.top-0.z-40(className="md:shadow-none")
  div.container.mx-auto
    div.relative.flex.items-center.justify-between.md-20.align(className=cn(mobileMenuOpen ? "shadow-md": "", "md:h-24"))
      // mobile menu
      div(className="md:hidden").flex.absolute.inset-y-0.right-0.item-center.select-none
        // mobile menu control
        button(
          className=cn("inline-flex items-center justify-center p-2 rounded-md text-gray-800 transition duration-150 ease-in-out z-10 select-none")
          aria-label="Main Menu"
          aria-expanded="false" 
          onClick=toggleMenu
        )
          svg.h-6.w-6(
            className=cn(mobileMenuOpen ? "hidden": "block")
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          )
            path(strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16")
          svg.h-6.w-6(
            className=cn(mobileMenuOpen ? "block": "hidden")
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24" stroke="currentColor"
          )
            path(strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12")
            
      // Desktop menu
      div(className="sm:items-stretch").flex-1.flex.items-center.justify-start.relative
        // logo 
        div.flex-shrink-0.flex.flex-row.items-center.justift-center
          Link.block.h-16.w-auto.mr-0(to="/" className="md:h-20 md:mr-2")
            img.inline-block.max-h-full(src="/images/keenmony-logo.jpg" alt="Keenmony Logo")
          Link.inline-block.text-sm.font-bold(to="/" className="md:text-lg") #{ t('keenmony_ltd') }
        div(className="md:flex").hidden.absolute.inset-y-0.right-0.ml-auto.justify-center.flex-row.items-center
          Link(to="/"+i18n.language+"/").px-3.py-2.nav-link #{t('navbar.home')}
          Link(to="/"+i18n.language+"/company").ml-2.px-3.py-2.nav-link #{t('navbar.company')}
          Link(to="/"+i18n.language+"/products").ml-2.px-3.py-2.nav-link #{t('navbar.products')}
          NavHashLink(smooth=true to="/"+i18n.language+"/#contact-us").ml-2.px-3.py-2.nav-link #{t('navbar.contact')}
          .border-l-2.border-gray-300.pl-4.flex.flex-row.items-center.justify-center
            img.inline-block.w-4(src="/images/svg/earth.svg")
            if (i18n.language === "en")
              a.w-12(href=changeLanguagePath style={lineHeight: '15px'}) 中
            else
              a.w-12(href=changeLanguagePath) EN
  // hidden mobile menu
  div(
    className=cn('sm:hidden overflow-hidden')
    style={ maxHeight: (mobileMenuOpen ? "400px": "0px"), transition: "max-height 400ms ease-in-out"})
    .px-2.pt-2.pb-3
      Link.nav-link-mobile(onClick=()=>setMobileMenuOpen(false) to="/"+i18n.language+"/" role="menuitem") #{t('navbar.home')}
      Link.nav-link-mobile(onClick=()=>setMobileMenuOpen(false) to="/"+i18n.language+"/company" role="menuitem") #{t('navbar.company')}
      Link.nav-link-mobile(onClick=()=>setMobileMenuOpen(false) to="/"+i18n.language+"/products" role="menuitem") #{t('navbar.products')}
      NavHashLink.nav-link-mobile(smooth=true  onClick=()=>setMobileMenuOpen(false) to="/"+i18n.language+"/#contact-us" role="menuitem") #{t('navbar.contact')}
      .flex.flex-row.items-center.justify-center.my-3
        img.inline-block.w-4.mr-2(src="/images/svg/earth.svg")
        if (i18n.language == "en")
          a.w-8(href=changeLanguagePath style={lineHeight: '15px'}) 䌓中
        else
          a.w-8(href=changeLanguagePath) EN
    `
}

export default Navbar;