export default {
  keenmony_ltd: "Keenmony Limited",
  "Read More": "Read More",
  copyright: "Copyright © {{year}} Keenmony Limited ",
  navbar: {
    "home": "Home",
    "company": "Our Company",
    "products": "Products",
    "contact": "Contact Us",
  },
  "Fuel your Adventure": "Fuel your Adventure",
  "Offering the best of nature": "Offering the best of nature",
  "View Our Products": "View Our Products",

  home: {
    company_title: "Our Company",
    company_p: "Keenmony is an importer, distributor and manufacturer of a wide range of Pulses, Starches and Flour from around the world for over 40 years. We supply manufacturers, food processors and retailers with the best quality and stability of products, which let us have the privileges as one of the industry’s leading suppliers. Our team are greatly experienced and each of us specialized in different products and exploring worldwide to source suitable products for our customers. We pride ourselves for delivering natural and sustainable ingredients and solutions to our customers.",
    
    product_title: "Products",
    product_p: "Keenmony supplies a wide range of raw materials which covers pulses, starches, flour and more.",
    product_pulses: "The following article covers a topic that has recently moved to center stage–at least it seems that way. If you’ve been thinking you need to know more about unconditional love, here’s your opportunity.",
    product_starches: "The following article covers a topic that has recently moved to center stage–at least it seems that way. If you’ve been thinking you need to know more about unconditional love, here’s your opportunity.",
    product_others: "The following article covers a topic that has recently moved to center stage–at least it seems that way. If you’ve been thinking you need to know more about unconditional love, here’s your opportunity.",
  },
  products: {
    pulses: "Pulses",
    starches: "Starches",
    others: "Others",
  },
  brand_title: "Our Brand",
  brand_p: "Dragon-Fish, a symbol of consistent high quality, has been a notable and reliable brand in the field since 1998. Only products that meet with our standard are selected to be part of our portfolio, to ensure the consistency of quality and meet customers’ needs.",
  cta: "“We strive for the highest quality and always delivering on our promises”",

  contact: {
    title: "How can we help you?",
    p: "Many people has the notion that enlightenment is one state. Many also believe that when it is attained, a person is forever in that state.",
    required: "Required",
    fullname: "Full name",
    email: "Email",
    company: "Company",
    products_interested: "Products Interested",
    message: "Message",
    submit: "Submit",
    address: "Address",
    address_1: "Rm 1205, 12/F, Arion Commercial Bldg",
    address_2: "8 Queen's Road West",
    address_3: "Sheung Wan, Hong Kong", 
    tel: "Tel.",
    fax: "Fax",
    office_hours: "Office Hours",
    hours_1: "Mon-Fri 0900-1730",
    hours_2: "Sat 0900-1300",
  },
  company: {
    history: "Our History",
    about: 'About Keenmony',
    keenmony: 'Keenmony',
    more: 'More than just a trading company',
    p: 'Keenmony Limited is an exporter of agricultural products to numerous countries worldwide. We offer various agricultural products for customers and are mainly focusing on pulses, and flour.',
    cert: {
      title: "Certificates",
      HACCP: "HACCP Certificate",
      ISO: "ISO Certificate",
    }
  }
}