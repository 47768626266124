import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { NavHashLink } from 'react-router-hash-link';
import FadeInOutWrapper from "../FadeInOutWrapper";
import { AnimatePresence } from 'framer-motion';
import Fade from 'react-reveal/Fade';

const Footer = (props) => {
  const { lang } = useParams();
  const year = (new Date).getFullYear();
  const { t, i18n } = useTranslation();
  const copyright = t('copyright', { year });
  const [ showToTop, setShowToTop] = useState(false);

  useEffect(() => {
    if ( lang !== i18n.language ) {
      i18n.changeLanguage(lang);
    }
  }, []);

  const offset = window.pageYOffset;
    useEffect(() => {
      window.addEventListener('scroll', () => setShowToTop(window.pageYOffset > 80));
      return window.removeEventListener('scroll', setShowToTop);
  }, []);


  return pug`
footer.w-full.bg-gray-800.relative  
  if showToTop
    AnimatePresence(exitBeforeEnter initial=false)
      FadeInOutWrapper
        NavHashLink.fixed.bottom-0.right-0.w-12.bg-orange-500.rounded.shadow-lg.p-2.m-4(className="md:w-16 md:p-4" smooth=true to="#top")
          img(src="/images/svg/to-top.svg")
  .container.mx-auto.flex.flex-col.py-8.justify-center.items-center(className="md:px-8 md:flex-row md:justify-between md:items-end")
    .flex.flex-col.justify-center.items-center.mb-5(className="sm:flex-row sm:justify-start")
      .flex.flex-col.justify-center.items-center
        img.inline-block.h-16.w-auto(src="/images/svg/keenmony-logo.svg" alt="Keenmony Logo")
      .flex-col.mt-3(className="sm:mt-0")
        h5.text-lg.text-white.font-bold.text-center(className="sm:text-left") #{t('keenmony_ltd')}
        span.text-sm.text-white #{t('Offering the best of nature')}
    Fade(bottom)
      .flex.justify-center.flex-row.items-start.w-20.items-center(className="md:w-32")
        a.inline-block.w-10.mr-4(className="md:w-32" href="https://www.facebook.com/keenmony" target="_blank")
          img(src="/images/svg/facebook.svg")
        a.inline-block.w-10.mr-4(className="md:w-32" href="https://www.instagram.com/keenmony/" target="_blank")
          img(src="/images/svg/instagram.svg")
        a.inline-block.w-10(className="md:w-32" href="https://www.linkedin.com/company/keenmony-ltd" target="_blank")
          img(src="/images/svg/linkedin.svg")

  .w-full.text-center.py-6
    span.text-white.text-xs(className="sm:text-sm md:text-base") #{t('copyright', {year: year})}

  `
}
export default Footer;