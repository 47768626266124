import React, { useState, useEffect } from 'react';
import { createClient } from '../../plugins/contentful';
import FadeInWrapper from "../../animations/FadeInWrapper"
import { useParams } from 'react-router';
import { useTranslation } from "react-i18next";
import FadeInOutWrapper from "../../components/FadeInOutWrapper"
import styles from './styles.module.scss';
import Fade from 'react-reveal/Fade';
import { useGA4React } from 'ga-4-react';

const CompanyPage = () => {
  const client = createClient();
  const [companies, setCompanies] = useState();
  const [ loaded, setLoaded ] = useState(false);
  const { t, i18n } = useTranslation();
  const { lang } = useParams();

  const ga = useGA4React(process.env.REACT_APP_GA);
  console.log(ga);

  useEffect(() => {
    if (ga) {
      if (lang) {
        ga.pageview(`/${lang}/company`);
      } else {
        ga.pageview("/company");
      }
    }
  }, [ga, lang]);

  const certs = {
    "en": [
      { transKey: "company.cert.HACCP", imgPath: "/images/HACCP_en.jpg"},
      { transKey: "company.cert.ISO", imgPath: "/images/ISO_en.jpg"},
    ],
    "zh": [
      { transKey: "company.cert.HACCP", imgPath: "/images/HACCP_zh.jpg"},
      { transKey: "company.cert.ISO", imgPath: "/images/ISO_zh.jpg"},
    ],
  }

  useEffect(() => {
    if ( lang !== i18n.language ) {
      i18n.changeLanguage(lang);
    }
  }, []);

  useEffect(() => {
    (async () => {
      const companies = await client
      .getEntries({
        'content_type': 'company',
        'order': 'sys.createdAt',
        'locale': lang === 'en' ? 'en-US': 'zh-Hant-HK',
      })
      .then(({ items }) => {
        return items.map(item => {
          return {
            title: item.fields.title,
            description: item.fields.description,
          }
        })
      });
      setCompanies(companies);
      setLoaded(true);
    })();
  }, [lang])
  
  return pug`
FadeInOutWrapper
  .bg-white.w-full
    .container.mx-auto
      .bg-cover.bg-center.bg-fixed.flex.flex-col.justify-center.items-center(className="bg-center md:mt-8" style={ height: "500px", backgroundImage: "url(/images/bg-company.jpg)" })
        FadeInWrapper
          h6.text-lg.text-white.leading-6.select-none.text-shadow #{t('company.history')}
          h2.text-4xl.font-bold.leading-10.text-white.select-none.text-shadow #{t('company.about')}
      if loaded
        .mt-8.flex.flex-row.flex-wrap
          each company, idx in companies
            Fade(key=("company-" + idx))
              div.company.flex.flex-col.w-full.p-6.m-4(className="md:m-0 md:mb-4 md:w-1/2 hover:bg-gray-200 hover:shadow-lg  transition-all duration-500 ease-in-out")
                h3.leading-1.text-2xl.text-left.mb-2.select-none #{ company.title }
                p.text-justify.select-none.text-gray-800 #{ company.description }

      h3.text-2xl.mb-8.mt-8.select-none #{t('company.cert.title')}
      .mb-16.flex.flex-col.flew-wrap.justify-around.px-8(className="md:flex-row")
        .flex.flex-col.justify-center.items-center.w-full.mb-8(className="md:w-1/4 md:mb-0")
          Fade(bottom)
            img.block.mb-3(src=certs[i18n.language][0]['imgPath'])
            span.text-base.select-none #{t(certs[i18n.language][0]['transKey'])}
        .flex.flex-col.justify-center.items-center.w-full(className="md:w-1/4")
          Fade(bottom)
            img.block.mb-3(src=certs[i18n.language][1]['imgPath'])
            span.text-base.select-none #{t(certs[i18n.language][1]['transKey'])}
        

      
      
      



  `
}
export default CompanyPage;