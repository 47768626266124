import React from 'react';
import { motion } from 'framer-motion';

const transition = {
  duration: 1,
  ease: [0.43, 0.13, 0.23, 0.96]
};

const imageVariants = {
  exit: { opacity: 0, transition },
  enter: {
    opacity: 1,
    transition
  }
};

const FadeInOutWrapper = (props) => {
  return (
  <motion.div variants={imageVariants} initial="exit" animate="enter" exit="exit">
    { props.children }
  </motion.div>)
}
export default FadeInOutWrapper;