import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from './translations/en_us';
import zhTranslation from './translations/zh_hk';

const resources = {
  en: {
    translation: enTranslation,
  },
  zh: {
    translation: zhTranslation,
  }
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
  })

  export default i18n;