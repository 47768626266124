export default {
  keenmony_ltd: "國聯貿易公司",
  "Read More": "了解更多",
  copyright: "版權所有 © {{year}} 國聯貿易公司",
  navbar: {
    "home": "主頁",
    "company": "關於我們",
    "products": "產品",
    "contact": "聯絡我們",
  },
  "Fuel your Adventure": "農產品原料全球供應商",
  "Offering the best of nature": "把來自大自然的最佳產品帶給你",
  "View Our Products": "查看我們的產品",

  home: {
    company_title: "公司簡介",
    company_p: "國聯貿易公司為來自世界各地的各種豆類，澱粉和麵粉的進口商，分銷商和製造商，迄今已有40多年的歷史。 我們為製造商，食品加工商和零售商提供最佳質量和穩定性的產品，這使我們有幸成為業內領先的企業之一。 我們的團隊經驗豐富，我們每個人都專注於不同的產品，並在全球進行探索，以為我們的客戶提供高質而合適的產品。我們為向客戶提供天然且可持續發展的原料和解決方案而感到滿足。",
    
    product_title: "產品",
    product_p: "我們為您尋找並提供各種天然原材料，包括豆類，澱粉和麵粉等等",
    product_pulses: "The following article covers a topic that has recently moved to center stage–at least it seems that way. If you’ve been thinking you need to know more about unconditional love, here’s your opportunity.",
    product_starches: "The following article covers a topic that has recently moved to center stage–at least it seems that way. If you’ve been thinking you need to know more about unconditional love, here’s your opportunity.",
    product_others: "The following article covers a topic that has recently moved to center stage–at least it seems that way. If you’ve been thinking you need to know more about unconditional love, here’s your opportunity.",
  },
  products: {
    pulses: "豆類",
    starches: "澱粉",
    others: "其他",
  },
  cta: "“我們追求最高品質，並始終兌現我們的承諾”",
  brand_title: "品牌",
  brand_p: "自1998年以來，龍魚牌一直是優質產品的象徵，為業內知名與可靠的品牌。只有符合我們標準的產品，才能被挑選並加入成為我們龍魚牌之一，以確保我們的質量始終如一並能滿足客戶的需求。",

  contact: {
    title: "有興趣購買？馬上聯絡我們。",
    p: "Many people has the notion that enlightenment is one state. Many also believe that when it is attained, a person is forever in that state.",
    required: "必需",
    fullname: "稱呼",
    email: "電郵地址",
    company: "公司地址",
    products_interested: "有興趣的產品",
    message: "訊息",
    submit: "送出",
    address: "地址",
    address_1: "香港上環皇后大道西8號",
    address_2: "聯發商業中心 12樓 1205室",
    address_3: "", 
    tel: "電話",
    fax: "傳真",
    office_hours: "辦公時間",
    hours_1: "星期一至五 0900-1730",
    hours_2: "星期六 0900-1300",
  },
  company: {
    history: "公司歷史",
    about: '關於國聯貿易公司',
    keenmony: '國聯',
    more: '不止於一間商貿公司',
    p: '國聯貿易公司在全球各地尋找天然原材料，並負責為您安排所有進出口事宜。 我們為客戶提供各種農產品，主要業務為豆類和粉類。',
    cert: {
      title: "認證證書",
      HACCP: "HACCP 體系認證證書",
      ISO: "ISO國際標準驗證證書",
    }
  }
}