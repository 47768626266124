import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { withRouter, useParams } from 'react-router';
import { HashLink } from 'react-router-hash-link';
import FadeInOutWrapper from "../../components/FadeInOutWrapper"
import Fade from "react-reveal/Fade";
import Reveal from "react-reveal/Reveal";
import { useGA4React } from 'ga-4-react';

import "./styles.scss";

const HomePage = (props) => {
  const { t, i18n } = useTranslation();
  const { lang } = useParams();
  //useLocaleRedirection();

  const ga = useGA4React(process.env.REACT_APP_GA);
  
  useEffect(() => {
    if (ga) {
      if (lang) {
        ga.pageview(`/${lang}/`);
      } else {
        ga.pageview("/");
      }
    }
  }, [ga]);

  useEffect(() => {
    if ( lang !== i18n.language ) {
      i18n.changeLanguage(lang);
    }
  }, []);

  const mapSettings =  {
    "height":480,
    "width": "100%",
    "zoom":18,
    "queryString": "Arion Commercial Centre, Queen's Road West, Possession Point, Hong Kong",
    "place_id":"ChIJRd297H4ABDQRGN1i6zA2Rkg",
    "satellite":false,
    "centerCoord":[22.286483567759237,114.14841795000001],
    "cid":"0x48463630eb62dd18","lang":"en","cityUrl":"/china/shenzhen",
    "cityAnchorText":"Map of Shenzhen, Guangdong, China",
    "id":"map-9cd199b9cc5410cd3b1ad21cab2e54d3",
    "embed_id":"285885"
  };
  const oneMapReady = window.OneMap && window.OneMap.initMap;
  useEffect(() => {
    window.OneMap.initMap(mapSettings);
  }, []);

  return pug`
FadeInOutWrapper
  // home slider
  .sliders.h-auto.my-none.w-full
    #slider
      .bg-cover.bg-center.py-24.px-10.object-fill.text-white.flex.flex-col.items-center.justify-center(
        className="md:items-start" style={ height: "calc(100vh - 96px)", backgroundImage: "url(/images/home-bg.jpg)"}
      )
        Fade
          div(className="md:w-1/2 text-left")
            h2.text-4xl.text-shadow.select-none(className="md:text-6xl") #{t('Fuel your Adventure')}
            p.select-none.text-xl.mb-4.text-shadow.select-none(className="md:text-4xl") #{t('Offering the best of nature')}
            Link.btn.btn-primary.rounded.shadow-lg(to="/"+i18n.language+"/products" as="button") #{t('View Our Products')}

  // our company
  .bg-white.w-full
    .flex.container.mx-auto.px-3.py-16.flex-wrap
      Fade
        .flex-1.text-center.mb-5(className="md:text-left w-1/2 md:pb-3")
          h3.text-3xl.text-gray-800.mb-6.select-none #{ t('home.company_title') }
          p.text-gray-700.tracking-wide.font-normal.text-justify.pl-4.pr-4.select-none(className="md:pl-0 md:pr-10") #{ t('home.company_p')}
          Link.btn.btn-primary.mx-auto.mt-8.rounded(className="md:mx-0" to="/"+i18n.language+"/company") #{ t('Read More') }
      Fade
        .flex-shrink(className="sm:pl-5")
          img.block(className="md:ml-auto md:max-w-sm" src="/images/bg-company2.jpg")

  // our product
  .bg-gray-100.w-full
    .container.mx-auto.px-3.py-16
      .w-full.text-center.mb-8(className="md:mb-16")
        h3.text-3xl #{t('home.product_title') }
        span.select-none #{t('home.product_p')}
      Fade(bottom)
        .flex.flex-col.flex-wrap.w-full(className="md:flex-row")
          HashLink.flex.flex-col.px-8.justify-center.items-center.mb-8.w-full(className="md:w-1/3" to="/"+i18n.language+"/products#pulses" smooth=true)
            img.inline-block.w-16(src="/images/svg/beans.svg")
            h5.text-lg.font-bold.my-4.select-none #{t('products.pulses')}
          HashLink.flex.flex-col.px-8.justify-center.items-center.mb-8.w-full(className="md:w-1/3" to="/"+i18n.language+"/products#starches" smooth=true)
            img.inline-block.w-16(src="/images/svg/starches.svg")
            h5.text-lg.font-bold.my-4.select-none #{t('products.starches')}
          HashLink.flex.flex-col.px-8.justify-center.items-center.mb-8.w-full(className="md:w-1/3" to="/"+i18n.language+"/products#others" smooth=true)
            img.inline-block.w-16(src="/images/svg/others.svg")
            h5.text-lg.font-bold.my-4.select-none #{t('products.others')}
  // our brand
  .bg-white.w-full
    .container.mx-auto.px-3.py-16
      .flex.flex-wrap
        div.text-left(className="md:w-1/3")
          Fade
            img(src="/images/dragon-fish-logo.jpg")
        div(className="md:w-2/3")
          h3.text-center.text-3xl.text-gray-800.mb-6.select-none(className="md:text-left") #{t('brand_title')}
          p.text-lg.text-center.select-none.text-gray-800(className="md:text-left") #{t('brand_p')}
  // contact form
  .bg-gray-100.w-full#contact-us
    .flex.container.mx-auto.px-3.py-16.flex-wrap
      .flex-1.text-left(className="md:pr-10")
        h3.text-3xl.font-bold.text-center.mb-4.select-none(className="md:text-left") #{ t('contact.title') }
        form.mb-8(className="md:mb-4" name="contact" method="POST")
          input(type="hidden" name="form-name" value="contact")
          .form-group
            label
              span.form-field #{ t('contact.fullname') }
              span.form-required *#{ t('contact.required') }
            input(type="text" name="fullname" placeholder="Joe Chan" required)
          .form-group
            label
              span.form-field #{ t('contact.email') }
              span.form-required *#{ t('contact.required') }
            input(type="email" name="email" required)

          .form-group
            label
              span.form-fied #{ t('contact.company') }
            input(type="text" name="company")
          .form-group
            label
              span.form-fied #{ t('contact.products_interested') }
            select(name="product-interested" required)
              option(value=t('products.pulses')) #{ t('products.pulses') }
              option(value=t('products.starches')) #{ t('products.starches') }
              option(value=t('products.others')) #{ t('products.others') }
          .form-group
            label
              span.form-fied #{ t('contact.message') }
            textarea.border-2(name="message" cols="60" rows="7" required)

          button.btn.btn-primary.mt-4.rounded.shadow-md(type="submit") #{ t('contact.submit') }
          
      .flex-1.text-left
        #wrapper-9cd199b9cc5410cd3b1ad21cab2e54d3.mb-6
          #map-9cd199b9cc5410cd3b1ad21cab2e54d3
        h6.text-xl.mb-2.font-bold #{ t('contact.address') }
        p.whitespace-pre.mb-4.select-none
          | #{ t('contact.address_1') }
          | #{ t('contact.address_2') }
          | #{ t('contact.address_3') } 

        h6.text-xl.mb-2.font-bold #{ t('contact.tel') }
        a.mb-4.block(href="tel:+85228586928") +852 2858 6928

        h6.text-xl.mb-2.font-bold #{ t('contact.fax') }
        a.mb-4.block(href="tel:+85228586928") +852 2549 3816
  `;
}

export default withRouter(HomePage)