import React, {useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from "./pages/Home";
import CompanyPage from './pages/Company';
import ProductsPage from "./pages/Products";
import Footer from './components/Footer';
import { AnimatePresence } from 'framer-motion';
import { useGA4React } from 'ga-4-react';

function App() { 

  const ga = useGA4React(process.env.REACT_APP_GA);

  return (
    <div className="App">
      <BrowserRouter>
          <Navbar></Navbar>
          <Route render={({location}) => (
              <AnimatePresence exitBeforeEnter initial={false}>
                <Switch location={location} key={location.pathname}>
                  <Route path="/:lang(en|zh)?/products" component={ProductsPage}></Route>
                  <Route path="/:lang(en|zh)?/company" component={CompanyPage}></Route>
                  <Route path="/:lang(en|zh)?/" exact>
                      <HomePage></HomePage>
                  </Route>
                </Switch>
              </AnimatePresence>
            )}></Route>
          <Footer></Footer>
      </BrowserRouter>
    </div>
  )
}

export default App;